import { io } from 'socket.io-client'
import React, { useEffect, useState } from 'react'
import logisticService from '../services/logistics'

// const sockio_url = 'https://api.totheloners.com'
// const sockio_url = '127.0.0.1:5001'
const sockio_url = process.env['REACT_APP_SOCKET_IO_URL']
const chatRoomSocket = io(sockio_url)
const rtcSocket = io(sockio_url)

const ChatRoom = () => {
  const [messages, setMessages] = useState([])
  const [enteredMessage, setEnteredMessage] = useState('')

  function onSubmitMessage(event) {
    event.preventDefault()
    const generatedUuid = self.crypto.randomUUID()

    chatRoomSocket.timeout(5000).emit('message', {
      text: enteredMessage,
      room: 'room1',
      messageId: generatedUuid,
      threadId: 1,
      messageType: 'text',
      userId: 1,
      client: 'web'
    })
    setEnteredMessage('')
  }

  function onTriggerCelery(event) {
    event.preventDefault()

    logisticService.basicCelery()
    console.log(`clicked celery`)
  }

  useEffect(() => {
    chatRoomSocket.on('connect', (socket) => {
      console.log('server connected')
    })
    chatRoomSocket.on('disconnect', () => {
      console.log('server disconnected')
    })
    chatRoomSocket.on('message', (value) => {
      setMessages(previous => [...previous, value['text']])
      console.log(`received message ${JSON.stringify(value)}!`)
    })
    chatRoomSocket.emit('join', { room: 'room1' })
    logisticService.getMessages().then(response => {
      let dd = response.data['data']
      setMessages(dd.map(message => message.text))
    }).catch(e => {
      console.log(`the get messages error is ${e}`)
    })
  }, [])

  return (
    <>
      <p>welcome to chat room</p>
      <form onSubmit={onSubmitMessage} style={{ display: 'inline-block' }}>
        <input
          onChange={e => setEnteredMessage(e.target.value)}
          value={enteredMessage}
        />

        <button type="submit">Submit</button>
      </form>
      <button onClick={onTriggerCelery}>Trigger Celery</button>
      <ul>
        {
          messages.map((message, index) =>
            <li key={index}>{message}</li>
          )
        }
      </ul>
    </>
  )
}

export default ChatRoom