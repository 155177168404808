import axios from 'axios'

const baseUrl = process.env['REACT_APP_API_BASE_URL']

const logVisit = () => {
  return axios.post(`${baseUrl}/monitor/visit`)
}

const getMessages = () => {
  return axios.get(`${baseUrl}/monitor/messages`)
}

const basicCelery = () => {
  return axios.post(`${baseUrl}/tasks/basic`, {})
}

const uploadFile = (body) => {
  return axios.post(`${baseUrl}/files/uploads`, body)
}

const downloadFile = (filename) => {
  return axios.get(`${baseUrl}/files/downloads/${filename}`, { responseType: 'blob' })
}

const streamVideoFile = (filename, config) => {
  return axios.get(`${baseUrl}/files/stream/${filename}`, config)
}

const getCameraFeed = () => {
  return axios.get(`${baseUrl}/monitor/video`)
}

const openCamera = () => {
  return axios.post(`${baseUrl}/monitor/video`, {
    op: 'on'
  })
}

const closeCamera = () => {
  return axios.post(`${baseUrl}/monitor/video`,
    {
      op: 'off'
    })
}

export default {
  logVisit,
  basicCelery,
  uploadFile,
  downloadFile,
  closeCamera,
  openCamera,
  getCameraFeed,
  streamVideoFile,
  getMessages
}